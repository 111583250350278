<template>
    <div>
        <mercur-card class="reset-password-form">
            <h2 class="font-weight-normal">Set your new password</h2>
            <form @submit.prevent="submitForm" autocomplete="off">
                <mercur-input v-model="form.username" id="username" type="email" :class="getValidationClass($v, 'username')">
                    Email
                    <template #note>
                        <span class="form-error" v-if="!$v.form.username.required">Username is required</span>
                    </template>
                </mercur-input>

                <mercur-input v-model="form.newPassword" id="newPassword" type="password" :class="getValidationClass($v, 'newPassword')">
                    Password
                    <template #note>
                        <span class="form-error" v-if="!$v.form.newPassword.required">Password is required</span>
                    </template>
                </mercur-input>

                <mercur-input v-model="form.passwordRepeat" id="newPassword" type="password" :class="getValidationClass($v, 'passwordRepeat')">
                    Password again
                    <template #note>
                        <span class="form-error" v-if="!$v.form.passwordRepeat.required">Password again is required</span>
                        <span class="form-error" v-else-if="!$v.form.passwordRepeat.sameAsPassword">Passwords are not matching</span>
                    </template>
                </mercur-input>

                <div class="mt-3">
                    <mercur-button class="btn btn-primary" type="submit" :disabled="loading">Reset Password</mercur-button>
                </div>
            </form>
        </mercur-card>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import { required, sameAs } from 'vuelidate/lib/validators'

import FormMixin from '@/mixins/Form'
export default {
    name: 'PasswordRecoverView',
    mixins: [FormMixin],
    data () {
        return {
            form: {},
            action: CONFIG.API.ROUTES.ACCOUNT.PASSWORD.RESET,
        }
    },
    validations: {
        form: {
            username: {
                required,
            },
            newPassword: {
                required,
            },
            passwordRepeat: {
                required,
                sameAsPassword: sameAs('newPassword'),
            },
        },
    },
    methods: {
        submitForm () {
            this.form.recoveryToken = this.$route.params.RECOVERY_TOKEN
            this.submit(this.action, this.form).then(data => {
                this.$root.$emit('notification:global', {
                    message: 'Password was reset',
                })
                setTimeout(() => {
                    this.$router.push({
                        name: 'LoginView',
                    })
                }, 1000)
            })
        },
    },
}
</script>

<style lang="scss" scoped>
    .reset-password-form {
        max-width: 500px;
        margin: auto;
        margin-top: 30vh;
    }
</style>
